/* This file contains all logic for the therapist pages, including but not limited to:
 * - Modals
 * - Tabs
 * - Sending of messages (exercises)
 */

/* global sendError */

function onTherapistPage() {
    if (window.sendMessageURL !== "/therapist/send/message" && window.sendMessageURL !== "/doctor/send/message") {
        return false;
    }
    return true;
}

/*
 * Default exercise schedule
 */
function createDefaultSchedule(exercise) {
    return {
        mon: 1,
        tue: 0,
        wed: 1,
        thu: 0,
        fri: 1,
        sat: 0,
        sun: 0,
        loops: exercise.loops,
        sets: exercise.sets,
        rest: exercise.rest,
        frequency: exercise.frequency,
        execution: exercise.execution_nl
    };
}

// /**
//  *  When going to the dashboard with no new patient,
//  *  Ask for the email and name op the patient for the back-end
//  */
// function new_patient_dashboard(csrf_token){
//     var form = document.createElement("form");
//     form.action = "patient_dashboard_new";
//     form.method = "post";
//     form.innerHTML = `

//     `;

//     Swal.fire({
//         title: trans.forms["new-patient"],
//         content: form,
//         buttons: {cancel: true},
//     });
// }
// window.new_patient_dashboard = new_patient_dashboard;

/*
 * Adds a new entry to the form_list.
 * i is the index of the exercise in the global exercises.
 */
function addFormList(i) {
    const exercise = window.exercises[i];
    let schedule = null;

    // Is the input values are undefined it means that the swal with input field is not used and
    // the exercise is imported from a predefined exercise list. Get the exercise information from this list.
    if ($("#exercise-loops").val() === undefined && $("#exercise-sets").val() === undefined) {
        if (exercise.schedule === undefined) {
            schedule = window.exerciseSchedules.find(obj => {
                return obj.exercise_id === exercise.id;
            });
            if (schedule.execution == null || schedule.execution === undefined || schedule.execution === "") {
                const locale = $("html").attr("lang");
                schedule.execution = locale === "en" ? exercise.execution_en : exercise.execution_nl;
            }
        }
    }

    // Schedule to add to the formlist's exerice
    // Done using a seperate varialble, so it won't change the exercise inside the window.exericses array.
    const s =
        schedule != null
            ? schedule
            : {
                  mon: Number($("#exercise-mon").prop("checked")) ? 1 : 0,
                  tue: Number($("#exercise-tue").prop("checked")) ? 1 : 0,
                  wed: Number($("#exercise-wed").prop("checked")) ? 1 : 0,
                  thu: Number($("#exercise-thu").prop("checked")) ? 1 : 0,
                  fri: Number($("#exercise-fri").prop("checked")) ? 1 : 0,
                  sat: Number($("#exercise-sat").prop("checked")) ? 1 : 0,
                  sun: Number($("#exercise-sun").prop("checked")) ? 1 : 0,
                  loops: Number($("#exercise-loops").val()),
                  sets: Number($("#exercise-sets").val()),
                  rest: Number($("#exercise-rest").val()),
                  frequency: Number($("#exercise-frequency").val()),
                  execution: $("#exercise-execution").val()
              };

    window.formList[window.formList.length] = {
        id: exercise.id,
        title: exercise.title,
        description: exercise.description,
        start_pos: exercise.start_pos,
        schedule: s,
        changed_date: 0,
        is_saved: 0,
        image_small: exercise.image_small,
        image: exercise.image,
        gif_large: exercise.gif_large,
        gif_small: exercise.gif_small,
        execution_en: exercise.execution_en,
        execution_nl: exercise.execution_nl
    };
}

/*
 * Returns the localised version or the english version (used as id"s and the like)
 * of all the weekdays corresponding to the truth value of the "local" variable.
 */
function getAllWeekdays(local) {
    if (local) {
        return [
            trans.forms.mo,
            trans.forms.tu,
            trans.forms.we,
            trans.forms.th,
            trans.forms.fr,
            trans.forms.sa,
            trans.forms.su
        ];
    }
    return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
}

/*
 * Removes the item with the said id from the form_list.
 */
function removeFormList(id) {
    window.nExercises -= 1;
    // delete form_list[id];
    window.formList.splice(id, 1);
}

/*
 * Fills the edit exercise form with the data received from the server.
 */
// function fillExerciseList(id) {
//     const exercise = window.exercises[id];
//     if (exercise !== undefined) {
//         Object.keys(exercise).forEach(key => {
//             if (Object.prototype.hasOwnProperty.call(exercise, key)) {
//                 let input = $(`#exercise-${key}`);
//                 // alert(`exercise-${key}`)
//                 // Fill in dates based on the global selected dates
//                 if (key === "schedule") {
//                     Object.keys(exercise[key]).forEach(day => {
//                         input = $(`#exercise-${day}`);
//                         if (Object.prototype.hasOwnProperty.call(exercise[key], day)) {
//                             input.prop("checked", exercise.schedule[day]);
//                         }
//                     });
//                 } else if (input.length > 0 && exercise[key]) {
//                     input.val(exercise[key]);
//                 } else if (exercise[key] === "0") {
//                     input.val(exercise[key]);
//                 } else if (input.length > 0) {
//                     input.val("");
//                 }
//             }
//         });
//     }
// }

/*
 * Activates the list edit form. (in turn deactiveates the exercise edit form)
 */
function activateListEdit() {
    $(".edit-exercise-details").addClass("d-none");
    $(".list-form").removeClass("d-none");
}

/*
 * When selected the exercise will be cloned to the bottom row.
 * The name of the label containts the corresponding id of the clone
 * which will be set when the exercise will be selected.
 */
function addExerciseToList(thisElem) {
    const name = $(thisElem).attr("name");

    // Index is the index of the global exercise data array, meaning that with the index you
    // can get all data of an exercise (that has been made available)
    // const indexx = $(".exercise-destination").children(`[name=${name}]`).children("data").val();
    const index = Number($(thisElem).children("data").val());

    // Check if exercises is not already added
    const exercise = window.exercises[index];
    if (window.formList.some(x => x.id === exercise.id)) return;

    // Clone the exercise
    const clone = $(thisElem).clone(true);
    clone.removeClass("dragable-exercise");
    clone.addClass("cloned-exercise");
    clone.attr("id", name);
    $(".exercise-destination").append(clone);

    window.nExercises += 1;
    addFormList(index);

    $(".exercise-destination")
        .children(`[name=${name}]`)
        .find(".icon-bar data")
        .val(window.formList.length + 1);
    $(thisElem).find(".checkbox i").text("check_box");

    // Change some classes so that the functionality works (e.g. shows the remove button.)
    const card = $(".exercise-destination").children(`[name=${name}]`);

    // const icon_bar =
    card.find(".icon-bar").removeClass("d-none");
    card.find(".icon-bar-top").addClass("d-none");
    const changeOrderDiv = card.find(".change-order");
    changeOrderDiv.removeClass("d-none");
    changeOrderDiv.children(".order-counter").text(window.nExercises);
    $(thisElem).addClass("active");

    // get the clone's image in case the exercise is added on the edit message screen.
    if (clone.find(".image-wrapper").find("img").length === 0) {
        window.testImage(
            `/storage/${exercise.image_small}`,
            clone.find(".image-wrapper"),
            "exercise-image",
            "Image",
            `exercise-img-${exercise.id}`,
            true,
            `/storage/${exercise.gif_small}`
        );
    }
}
window.addExerciseToList = addExerciseToList;

/*
 * Update an exercise that is already added to the formList.
 */
function updateExerciseInList(id) {
    const index = window.formList.findIndex(x => x.id === id);
    const changes = $(".edit-exercise-details").serializeArray();

    // Update the values
    for (let i = 0; i < changes.length; i += 1) {
        const name = changes[i].name.replace("exercise-", "");
        const { value } = changes[i];
        window.formList[index].schedule[name] = value;
    }

    // Update the weekdays
    const allDates = getAllWeekdays(false);
    for (let i = 0; i < allDates.length; i += 1) {
        const day = allDates[i];
        const input = $(`#exercise-${day}`);
        const value = input.prop("checked");
        if (value !== $(`#list-${day}`).prop("checked")) {
            window.formList[index]["changed_date"] = 1;
        }
        window.formList[index].schedule[day] = value ? 1 : 0;
    }
}

/*
 * Validate an exercise that is either send to the edit-exercise swal
 * or added to the formList.
 */
function validateEditedExercise() {
    const changes = $(".edit-exercise-details").serializeArray();

    // Map the array to a dict
    $.map(changes, function (n, _) {
        changes[n.name] = n.value;
    });

    // Check if all required fields are filled in.
    if (
        changes["exercise-loops"] === "" ||
        changes["exercise-sets"] === "" ||
        changes["exercise-rest"] === "" ||
        changes["exercise-frequency"] === "" ||
        changes["exercise-execution"] === ""
    ) {
        Swal.fire(trans.errors.error, trans.errors["empty-field"], "error");
        return false;
    }

    // Check if the data is correct according to the backend
    if (changes["exercise-loops"] < 1 || changes["exercise-loops"] > 20) {
        Swal.fire(trans.errors.error, trans.errors["field-loops"], "error");
        return false;
    }
    if (changes["exercise-sets"] < 1 || changes["exercise-sets"] > 20) {
        Swal.fire(trans.errors.error, trans.errors["field-sets"], "error");
        return false;
    }
    if (changes["exercise-rest"] < 0 || changes["exercise-rest"] > 1000) {
        Swal.fire(trans.errors.error, trans.errors["field-rest"], "error");
        return false;
    }
    if (changes["exercise-frequency"] < 1 || changes["exercise-frequency"] > 20) {
        Swal.fire(trans.errors.error, trans.errors["field-frequency"], "error");
        return false;
    }

    // Check for at least one weekday to be selected
    const weekDays = getAllWeekdays(false);
    let days = 0;
    for (let i = 0; i < weekDays.length; i += 1) {
        if ($(`#exercise-${weekDays[i]}`).val() === null || $(`#exercise-${weekDays[i]}`).val() === "") return false;
        if (Number($(`#exercise-${weekDays[i]}`).prop("checked")) === 1) days += 1;
    }
    if (days === 0) {
        Swal.fire(trans.errors.error, trans.errors["empty-days"], "error");
        return false;
    }

    return true;
}

function isClonedExercise(target) {
    return $(target).parents(".cloned-exercise").length >= 1;
}

/*
 * Retrieves the index of the exercise list starting from the border div.
 * Instead of cloned-exercises or dragable-exercises this was chosen because it can apply to both.
 */
function findArrayIdFromExercisesBorder(thisElem) {
    return $(thisElem).find(".order-counter").text() - 1;
}

/*
 * Retrieves the index of the exercise list starting from the the top-bar (works for most things inside an exercise)
 */
function findArrayIdFromTopBar(thisElem) {
    return findArrayIdFromExercisesBorder($(thisElem).parents(".border"));
}

/*
 * Given a child of the cloned exercise, deselects it.
 */
function deselectExercise(thisElem) {
    const clonedExercises = $(".cloned-exercise");
    const thisExercise = $(thisElem).closest(".cloned-exercise")[0];

    // Deactivates the parent exercise.
    const name = $(thisExercise).attr("id");
    $(`[name=${name}]`).find(".checkbox i").text("check_box_outline_blank");
    $(`[name=${name}]`).removeClass("active");

    // Why do I have to add this + 1 here?
    const arrayId = findArrayIdFromExercisesBorder($(thisExercise).children(".border")[0]);
    removeFormList(arrayId);

    // Deletes the actual exercise
    $(thisExercise).remove();

    // Changes the index number displayed to the user for all later occuring exercises.
    for (let i = 0; i < clonedExercises.length; i += 1) {
        if (clonedExercises[i] === thisExercise) {
            for (let j = i; j < clonedExercises.length; j += 1) {
                const exercise = clonedExercises[j];
                const idx = j;
                $(exercise).children().find(".order-counter").text(idx);
            }
            break;
        }
    }
}

/*
 * An exercise is selected (either a new one or a already chosen one).
 * Show the edit exercise view inside a swal.
 */
function editExercise(exercise, callback) {
    let schedule;
    if (exercise.schedule === null || exercise.schedule === undefined) {
        schedule = createDefaultSchedule(exercise);
    } else {
        schedule = exercise.schedule;
    }

    const dataa = {
        id: exercise.id,
        loops: schedule.loops,
        sets: schedule.sets,
        rest: schedule.rest,
        frequency: schedule.frequency,
        execution_nl: schedule.execution,
        execution_en: schedule.execution,
        schedule,
        onTherapistPage: onTherapistPage() ? 1 : 0
    };

    // Get the view using an request to the own server.
    $.ajax({
        method: "post",
        data: dataa,
        url: "/ajax/therapist/exercise/edit/view",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    }).done(data => {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = data;
        Swal.fire({
            icon: "",
            html: wrapper,
            confirmButtonText: trans.forms.save,
            cancelButtonText: trans.forms.cancel,
            showCancelButton: true,
            allowOutsideClick: false
            // keydownListenerCapture: true
        }).then(value => {
            if (value.isConfirmed) {
                callback();
            }
        });
    });
}

/*
 * Get the default exercise that belongs to a given exercise id.
 */
function idToExercise(id) {
    const result = window.exercises.find(obj => {
        return obj.id === id;
    });
    return result;
}

/*
 * Get the edited exercise that belongs to a given exercise id.
 */
function idToEditedExercise(id) {
    const result = window.formList.find(obj => {
        return obj.id === id;
    });
    return result;
}

/*
 * Swaps two object next to each other. If left is true, it will swap to the left, else it will swap to left.
 */
function swapArrayItems(idx, left, array) {
    let swapWith;
    if (left) {
        swapWith = idx - 1;
    } else {
        swapWith = idx + 1;
    }

    // The idx is 1-indexed (based on the displayed number), CURSE YOU WOUTER
    // Array is not so this is easy fix easy life
    const curItem = array[idx - 1];
    const swapItem = array[swapWith - 1];

    // eslint-disable-next-line no-param-reassign
    array[idx - 1] = swapItem;
    // eslint-disable-next-line no-param-reassign
    array[swapWith - 1] = curItem;
}

/*
 * Swaps this exercise (thisElem) with the left or right neighbour indicated by the left boolean.
 */
function swapExercises(thisElem, left) {
    const idx = parseInt(thisElem.parents().children().closest(".order-counter").text());
    const thisExercise = thisElem.parents().closest(".cloned-exercise");
    // console.log("this_exercise");
    // console.log(this_exercise);
    // console.log("idx " + idx);

    if (left) {
        let swapWith;
        // Its 1-indexed. I'm sorry
        if (idx === 2) {
            // Swap with the first element
            // eslint-disable-next-line prefer-destructuring
            swapWith = $(".exercise-destination").children()[0];
            $(thisExercise).insertBefore($(".exercise-destination").children()[0]);
        } else if (idx > 2) {
            swapWith = thisExercise.prev();
            $(thisExercise).insertBefore(thisExercise.prev());
        } else {
            // Shouldn't occur
            return;
        }
        $(swapWith).children().find(".order-counter").text(idx);
        thisElem
            .siblings()
            .closest(".order-counter")
            .text(idx - 1);
    } else {
        // Elem is last elem
        if (idx >= $(".exercise-destination").children().length) {
            return;
        }
        thisElem
            .siblings()
            .closest(".order-counter")
            .text(idx + 1);
        const swapWith = thisExercise.next();
        $(swapWith).children().find(".order-counter").text(idx);
        $(thisExercise).insertAfter(swapWith);
    }
    swapArrayItems(idx, left, window.formList);
}

/*
 * Toggles the is_saved variable in the form_list of the corresponding id.
 * Variable is used to save exercises as they are edited.
 */
function toggleFavInternal(id) {
    window.formList[id]["is_saved"] = window.formList[id]["is_saved"] ? 0 : 1;
}

/*
 * Called when the exercise list form is not valid in send_message on the therapist page.
 * TODO improve error?
 */
function listFormIsNotValid() {
    // console.log('INVALID')
    // $('#edit-list').trigger("click");
    // $(".list-form").validator("validate");
    Swal.fire(trans.forms["something-went-wrong"], trans.forms["list-error"], "error");
}

function sortOnOrderKey(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
}

/*
 * Validates the added exercises.
 */
function checkAddedExercises() {
    if (window.formList.length === 0) return false;
    const temp = window.formList;
    for (let i = 0; i < temp.length; i += 1) {
        // Remove exercises that are undefined
        if (!temp[i] === undefined) {
            temp.splice(i, 1);
        } else {
            temp[i]["changed_date"] = undefined;
        }
    }

    // Retrieves the selected exercises and puts them in the final_form_list array
    const finalFormList = [];
    const clonedExercises = $(".cloned-exercise");
    for (let i = 0; i < clonedExercises.length; i += 1) {
        const thisExercise = clonedExercises[i];
        const arrayId = $(thisExercise).children().find(".order-counter").text() - 1;
        if (temp[arrayId]) {
            temp[arrayId].order = arrayId;
            finalFormList.push(temp[arrayId]);
        }
    }

    finalFormList.sort(sortOnOrderKey);

    if (finalFormList.length === 0) {
        listFormIsNotValid();
        return false;
    }

    for (let i = 0; i < finalFormList.length; i += 1) {
        delete finalFormList[i].order;
        delete finalFormList[i]["changed_date"];
    }
    return finalFormList;
}

/* Checks whether start and end_date are valid.
 * They are given as strings.
 * Throws an error when a problem arises.
 */
function checkListDates(startDate, endDate) {
    if (!window.firstDateIsPastDayComparedToSecond(new Date(), new Date(endDate))) {
        Swal.fire(
            window.jsLocalArguments(trans.validation.after, {
                attribute: trans.forms["end-date"],
                date: trans.forms.today
            }),
            "",
            "error"
        );
        return false;
    }

    if (!window.firstDateIsPastDayComparedToSecond(new Date(startDate), new Date(endDate))) {
        Swal.fire(
            window.jsLocalArguments(trans.validation.before, {
                attribute: trans.forms["start-date"],
                date: trans.forms["end-date"]
            }),
            "",
            "error"
        );
        return false;
    }

    return true;
}

/*
 * Opens a sweetalert pop up with a single button (with specified button_text) and some text (text variable),
        when pressing the button the user will go back to editing the list.
 * icon is a prebuild sweetalert item string and swal_header is the desired header text.
 * It is for example called when a user adds exercises from an exercise list.
 */
function swalWithEditList(text, buttonText, icon, swalHeader) {
    const html = crel(
        "div",
        crel("p", text),
        crel(
            "button",
            {
                class: "ms-3 btn btn-primary btn-raised swal-button swal-button--agree",
                "data-bs-toggle": "modal",
                "data-bs-target": "#edit-exercise-modal",
                onclick: "Swal.close()"
            },
            buttonText
        )
    );
    Swal.fire({
        title: swalHeader,
        icon,
        html,
        showConfirmButton: false
    });
}

/*
 * Creates a single agenda (card)element.
 */
function createAgendaElem(item) {
    const elem = crel(
        "div",
        { class: "card border calender-card shadow" },
        crel("img", {
            class: "card-img-top calender-img",
            src:
                item.image_small === undefined
                    ? $(`[name=exercise-${item.id}]`).find("img").attr("src")
                    : `/storage/${item.image_small}`
        }),
        crel("card-body", crel("p", { class: "card-text" }, item.title))
    );
    return elem;
}

/*
 * Creates the agenda html from the given exercises array,
 * where every exercise contains a schedule.
 */
function createAgendaFrom(exercises) {
    const localDates = getAllWeekdays(true);
    const dates = getAllWeekdays(false);

    const temp = [];
    for (let i = 0; i < dates.length; i += 1) {
        temp.push(crel("th", { class: "col" }, crel("h4", localDates[i])));
    }

    const tableHead = crel("tr", temp);

    const tableBody = [];

    let weekDays = 0;
    for (let i = 0; i < exercises.length; i += 1) {
        const item = exercises[i];
        const row = [];
        if (item) {
            if (item.schedule) {
                for (let j = 0; j < dates.length; j += 1) {
                    const date = dates[j];
                    if (item.schedule[date]) {
                        row.push(crel("td", { class: "col" }, createAgendaElem(item)));
                        weekDays += 1;
                    } else {
                        row.push(
                            crel("td", { class: "col" }, crel("div", { class: "card border calender-card shadow" }))
                        );
                    }
                }
            }
        }

        tableBody.push(crel("tr", row));
    }

    if (weekDays === 0) {
        return null;
    }

    const html = crel(
        "div",
        { class: "row table-responsive m-0" },
        crel(
            "table",
            { class: "table table-hover table-sm text-c" },
            crel("thead", tableHead),
            crel("tbody", tableBody)
        )
    );
    // console.log(table_body)
    return html;
}
window.createAgendaFrom = createAgendaFrom;

/*
 * Creates the agenda html from the dictionary representation.
 */
function createAgendaHTML() {
    if (window.formList.length === 0) return null;
    return createAgendaFrom(window.formList);
}

/*
 * Calls the addExerciseToList function for
 * each exercise associated with the document to add it to the current list.
 * A document can have multiple lists, in which there can be multiple exercises.
 */
function addExercisesFromDoc(exercises) {
    Object.keys(exercises).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(exercises, key)) {
            const { content } = exercises[key];
            Object.keys(content).forEach(contentKey => {
                if (Object.prototype.hasOwnProperty.call(content, contentKey)) {
                    addExerciseToList(`[name=exercise-${content[contentKey].id}]`);
                }
            });
        }
    });
}

/* Adds exercises from an array to the editexerciselist.
 */
function addExercisesFromArray(exercises) {
    for (let i = 0; i < exercises.length; i += 1) {
        addExerciseToList(`[name=exercise-${exercises[i]}]`);
    }
}
window.addExercisesFromArray = addExercisesFromArray;

/*
 * Add exercises from a list to the edit exercise list.
 */
function selectListExercises(_) {
    const html = createAgendaHTML();
    if (html === null) {
        $("#therapist-calender").empty();
        $("#therapist-calender-add").html(`${trans.forms.add} <i class="material-icons md-18">add</i>`);
        return;
    }
    $("#therapist-calender").html(html);
    $("#therapist-calender-add").html(`${trans.forms.edit} <i class="material-icons md-18">check</i>`);
}
window.selectListExercises = selectListExercises;

/*
 * Creates a new psk input field.
 */
function addPSKFields(focus) {
    const newId = `psk${window.pskCounter}`;
    const cloned = crel(
        "div",
        { class: "form-group row row-psk" },
        crel(
            "div",
            { class: "col-6" },
            crel("input", { id: newId, name: newId, class: "col-md-4 form-control form-text-input", type: "text" })
        ),
        crel(
            "div",
            { class: "col-6" },
            crel(
                "button",
                { class: "btn remove-psk", onclick: "removePSK($(this))", type: "button" },
                crel("i", { class: "material-icons md-18" }, "cancel")
            ),
            crel(
                "button",
                { class: "btn add-psk", onclick: "addPSK2($(this))", type: "button" },
                crel("i", { class: "material-icons md-18", style: "color:#4285f4; font-size:24px;" }, "add")
            )
        )
    );
    window.firstPSKRef.parent().append(cloned); // clone =

    if (focus) cloned.firstChild.focus();
}

function addPSK2(thisElem) {
    // Remove current add button and place it in new psk row
    window.pskCounter += 1;
    thisElem.remove();
    addPSKFields(true);
}
window.addPSK2 = addPSK2;

/*
 * Adds all the PSKs to the page. Used for editing a message
 */
function addPSKsFromMessage() {
    for (let i = 0; i < window.messagePSKs.length; i += 1) {
        if (i === 0) {
            window.firstPSKRef = $(".add-psk").parent().parent();
        } else {
            $(".add-psk").remove();
            addPSKFields(false);
        }
        window.pskCounter += 1;
        $(`#psk${i}`).val(window.messagePSKs[i].name);
    }
}
window.addPSKsFromMessage = addPSKsFromMessage;

/*
 * Removes an input of the PSK fields.
 */
function removePSK(thisElem) {
    // Remove own psk row

    thisElem.parent().parent().remove();

    const rows = $(".row-psk");

    const lastRow = rows[rows.length - 1];
    const secCol = lastRow.children[1];

    if ($(secCol).find(".add-psk").length === 0) {
        const addButton = crel(
            "button",
            { class: "btn add-psk", onclick: "addPSK2($(this))", type: "button" },
            crel("i", { class: "material-icons md-18", style: "color:#4285f4; font-size:24px;" }, "add")
        );

        secCol.append(addButton);
    }
}
window.removePSK = removePSK;

/*
 * Calls the function receive the view from the server to show the exercises.
 */
// $(".open-exercise-modal-link").on("click", () => {
//     window.getAllExercisesAjax($("#view-exercises"));
// });

/*
 * Creates a document button with crel depending on the following inputs:
 * - URL: Url where the button should point to.
 * - icon: icon string used
 * - new_tab: boolean whether the button for opening the url in a new tab should be added.
 * - download: boolean, true when the download button should be present.
 */
function createDocButton(url, icon, newTab, download) {
    const result = crel(
        "a",
        { type: "button", href: url, class: "btn btn-info" },
        crel("i", { class: "material-icons md-18" }, icon)
    );

    if (newTab) {
        result.target = "_blank";
        result.rel = "noopener noreferrer";
    }

    if (download) {
        result.download = "download";
    }
    return result;
}

// Fill the list's settings to the make lists -> select -> exercise list view.
function fillListsettings(list) {
    $("#save-list-details").removeClass("d-none");
    window.edit = true;
    $("#description_en").val(list["description_en"]);
    $("#description_nl").val(list["description_nl"]);
    $("#title_en").val(list["title_en"]);
    $("#title_nl").val(list["title_nl"]);

    $("#list-preview").css("display", "none");
    $("#image_list").val("");

    // "list_description_en" : (onTherapistPage() ?  $("#description").val() : $("#description_en").val() ),
    // "list_description_nl" : (onTherapistPage() ? $("#description").val() : $("#description_nl").val() ),
    // "list_title_en" : (onTherapistPage() ?  $("#list_title").val() : $("#title_en").val() ) ,
    // "list_title_nl" : (onTherapistPage() ? $("#list_title").val() : $("#title_nl").val() ),
    // "saved" : (Number($("#list-saved").prop("checked")) ? 1 : 0),
}
window.fillListsettings = fillListsettings;

/**
 * Sends the response to a klacht from a therapist to the server.
 *
 * @param {*} id
 */
function sendReportResponse(id) {
    const response = $("#handle-report").val();

    const toSend = {
        report: id,
        response
    };

    $.post({
        url: "/therapist/report/respond",
        data: toSend,
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    })
        .done(function () {
            Swal.fire(trans.forms["send-succesfully"], "", "success").then(function () {
                window.location.reload();
            });
        })
        .fail(data => {
            sendError(data);
        })
        .always(_ => _);
}

/**
 * When a therapist wants to respond to a Klacht from a patient.
 * A swal is opened with a textarea. When the therapist send the
 * response the func sendReportResponse is called.
 */
function reportResponse(id, name) {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<h2>${trans.pagination.handle}</h2>
        U beantwoord de klacht van ${name}
        <div class='shadow-lg p-3 mb-5 bg-white rounded'>
        <textarea maxlength='190' style='height:100px' autofocus class='form-control shadow-none' id='handle-report'></textarea></div>`;
    Swal.fire({
        icon: "",
        html: wrapper,
        confirmButtonText: trans.forms.send,
        cancelButtonText: trans.forms.cancel,
        showCancelButton: true,
        allowOutsideClick: false
    }).then(value => {
        if (value.isConfirmed) {
            sendReportResponse(id);
        }
    });
}
window.reportResponse = reportResponse;

/*
 * When the DOM with all its elements are loaded.
 */
$(function () {
    /*
     * Saves the order and attributes of exercises in an array.
     * The order of items is the orders in which they appear in the array.
     */
    window.formList = [];

    /*
     * Saves whether a exercises linked to a document have been added or not.
     * Entries are made in select-doc.blade.php.
     */
    window.docExercises = {};

    /*
     * TODO Add language support.
     */
    window.LEEG = trans.forms["empty-field"];

    /*
     * Number of exercises present in the formList.
     */
    window.nExercises = 0;

    window.sendMessageURL = `${window.route_prefix}send/message`;
    window.updateMessageURL = `${window.route_prefix}edit/message`;

    window.currentSaveListState = false;

    /*
     * Adds another input to the PSK fields.
     */
    window.pskCounter = 0;
    window.firstPSKRef = null;

    /*
     * Loads the exercises via ajax and loads them into the DOM.
     */
    window.firstClickOpenExercise = true;

    /*
     * When an exercise is clicked on it will be cloned to the bottom row by calling addExerciseToList.
     * Despite its name it's not dragable, it a bit of legacy there.
     */
    $(".dragable-exercise")
        .off()
        .on("click", function (event) {
            // Makes sure that this div is pressed and not the youtube link button or icon.
            if (
                !event.target.classList.contains("youtube-link") &&
                (event.target.nodeName !== "I" || event.target.classList.contains("select-exercise")) &&
                (event.target.nodeName !== "BUTTON" || event.target.classList.contains("checkbox")) &&
                !isClonedExercise(event.target)
            ) {
                const name = $(this).attr("name");
                const id = $(`#${name}`);
                const thisElem = $(this);
                if ($(id).length === 1) {
                    // Exercise should be removed
                    deselectExercise(id);
                } else {
                    editExercise(idToExercise(Number(thisElem.attr("data-id"))), function () {
                        if (validateEditedExercise()) addExerciseToList(thisElem);
                    });
                }
            }
        });

    $("#list-saved").on("click", element => {
        /* when true, the fields for saving a list become required, otherwise not. */
        if (window.currentSaveListState !== element.currentTarget.checked) {
            $("#expand-question-list-title").toggle();
            $("#expand-question-list-description").toggle();
        } else {
            return;
        }

        const listTitle = document.getElementById("list_title");
        const listDescription = document.getElementById("description");

        window.currentSaveListState = element.currentTarget.checked;

        if (element.currentTarget.checked) {
            listTitle.required = true;
            listDescription.required = true;
        } else {
            listTitle.required = false;
            listDescription.required = false;

            // Reevaluate the fields in case of earlier required error
            $("#list_title").val($("#list_title").val()).trigger("change");
            $("#description").val($("#description").val()).trigger("change");
        }
    });

    /*
     * Swaps this exercise with his left neighbour.
     */
    $(".change-order-left").on("click", function () {
        swapExercises($(this), true);
    });

    /*
     * Swaps this exercise with his right neighbour.
     */
    $(".change-order-right").on("click", function () {
        swapExercises($(this), false);
    });

    /*
     * Toggles the filled in heart and the unfilled
     * and calls the toggleFavInternal function which does the logic for it.
     */
    $(".fav").on("click", function () {
        let arrayId = findArrayIdFromTopBar($(this));

        // No idea why I have to add this edge case, it should not.
        if (window.nExercises === 1) {
            arrayId = 0;
        }
        toggleFavInternal(arrayId);
        if ($(this).hasClass("selected")) {
            $(this).find("i").text("favorite_border");
            $(this).removeClass("selected");
        } else {
            $(this).addClass("selected");
            $(this).find("i").text("favorite");
        }
    });

    /*
     * When the list-checkbox is changed, the unedited dates
     * from the exercises are set to what this box is set to.
     */
    $(".list-checkbox").on("change", function () {
        if ($(this).is(":visible")) {
            const date = $(this).val();
            const value = $(this).prop("checked");
            for (let i = 0; i < window.formList.length; i += 1) {
                if (
                    window.formList[i] &&
                    (window.formList[i]["changed_date"] === 0 || window.formList[i]["changed_date"] === undefined)
                ) {
                    if (value === true) {
                        window.formList[i].schedule[date] = 1;
                    } else {
                        window.formList[i].schedule[date] = 0;
                    }
                }
            }
        }
    }); // Fix this

    /*
     * Deletes the exercise from the bottom row.
     * Also deletes the exercise from the internal representation leaving a hole behind.
     * The gap is removed when sending the form.
     */
    $(".delete-exercise").on("click", function () {
        deselectExercise($(this));
    });

    /*
     * When the user clicks the button,
     * hide the exercise list editor and open the exercise editor
     */
    $(".edit-exercise").on("click", function () {
        // eslint-disable-next-line radix
        const id = parseInt($(this).attr("data-id"), 10);

        editExercise(idToEditedExercise(id), function () {
            if (validateEditedExercise(id)) updateExerciseInList(id);
        });
    });

    /*
     * Submits the send-message form via ajax.
     */
    $("#send-message").on("click", function (e) {
        /*
         * Removes undefined holes from a list.
         */
        e.preventDefault();
        if ($(this).hasClass("disabled")) {
            return;
        }

        // If this is clicked multiple times then messages will be sent multiple times.
        $(this).addClass("disabled");

        // Check if messsage is filled in
        if ($("#therapist-message").val() === "" || $("#therapist-message").val() === null) {
            Swal.fire(trans.forms.message, trans.forms["message-error"], "error").then(() => {
                $("html, body").animate({ scrollTop: $("#therapist-message").offset().top }, 1000);
                $("#therapist-message").focus();
            });

            $(this).removeClass("disabled");
            $("#therapist-message").addClass("is-invalid");
            return;
        }
        $("#therapist-message").removeClass("is-invalid");
        $("#therapist-message").addClass("is-valid");

        // Check the dates
        // Alerts are thrown inside function.
        if (!checkListDates($("#start_date").val(), $("#end_date").val())) {
            $("html, body").animate(
                {
                    scrollTop: $("#end_date").offset().top
                },
                1000
            );
            $("#end_date").addClass("is-invalid");
            $(this).removeClass("disabled");
            return;
        }
        $("#end_date").removeClass("is-invalid");
        $("#start_date").addClass("is-valid");
        $("#end_date").addClass("is-valid");

        // Check the exercises
        const finalFormList = checkAddedExercises();
        if (finalFormList === false) {
            swalWithEditList(trans.forms["list-error"], trans.forms.oke, "error", trans.forms.exercises);
            $(this).removeClass("disabled");
            return;
        }

        /* Collect the PSK values
         */
        const psk = [];
        $("[id^=psk]").each((_, element) => {
            const val = $(element).val();
            if (val) {
                psk.push(val);
            }
        });

        /*
         * Collect all document id"s
         */
        const advice = [];
        for (let j = 0; j < window.docTypes.length; j += 1) {
            const doc = window.docTypes[j];
            let i = 0;
            while ($(`#${doc}-${i}`).length > 0) {
                const thisElem = $(`#${doc}-${i}`);
                const checked = $(thisElem).prop("checked");
                if (checked) {
                    advice.push($(thisElem).parentsUntil(".card-body").find(".advice_id").val());
                }
                i += 1;
            }
        }

        /*
         * Build the dictionary of data that will be sent to the server.
         */
        let tempTherapistListTitle = "";
        let tempTherapistListDescription = "";

        // Set default value for db when not saved (in this case these values will never be displayed)
        if (onTherapistPage() && Number($("#list-saved").prop("checked"))) {
            tempTherapistListTitle = $("#list_title").val();
            tempTherapistListDescription = $("#description").val();
        }

        const toSend = {
            target_email: window.patientEmail,
            target_name: window.patientName,
            advice_list: advice,
            message: $("#therapist-message").val(),
            list_description_en: onTherapistPage() ? tempTherapistListDescription : $("#description_en").val(),
            list_description_nl: onTherapistPage() ? tempTherapistListDescription : $("#description_nl").val(),
            list_title_en: onTherapistPage() ? tempTherapistListTitle : $("#title_en").val(),
            list_title_nl: onTherapistPage() ? tempTherapistListTitle : $("#title_nl").val(),
            saved: Number($("#list-saved").prop("checked")) ? 1 : 0,
            start_date: $("#start_date").val(),
            end_date: $("#end_date").val(),
            exercise_list: finalFormList,
            psk
        };

        // Update
        if (window.messageID) toSend.message_id = window.messageID;

        // Validates the message body
        Object.keys(toSend).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(toSend, key)) {
                if (toSend[key] === undefined || toSend[key] == null) {
                    listFormIsNotValid();
                    $(this).removeClass("disabled");
                    // eslint-disable-next-line no-useless-return
                    return;
                }
            }
        });

        // If demo stop and swal
        if (window.isDemo) {
            Swal.fire({
                title: "Demo",
                text: trans.demo["demo-message-sent"],
                icon: "success"
            }).then(_ => {
                window.location = `/demo${window.route_prefix}dashboard`;
            });
            return;
        }

        $.post({
            url: window.messageID ? window.updateMessageURL : window.sendMessageURL,
            data: toSend,
            headers: {
                "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
            }
        })
            .done(function () {
                Swal.fire(window.messageID ? trans.forms.saved : trans.forms["send-succesfully"], "", "success").then(
                    function () {
                        window.location = window.messageID
                            ? `${window.route_prefix}history`
                            : `${window.route_prefix}patients`;
                    }
                );
                // Make sure you can't click twice in a row when the timout is running.
                $(this).addClass("disabled");
            })
            .fail(data => {
                // console.log(data);
                sendError(data);
                $(this).removeClass("disabled");
            })
            .always(_ => _);
    });

    /*
     * When the checkbox for the select exercise list is clicked.
     * The corresponding array of exercises is put in a javascript array and added to the list using the helper function: addExercisesFromArray.
     * It also display a sweetalert to notify the user that the action has been successfull.
     * When one clicks the button the send-message screen will open again.
     */
    $(".exercise-list-select-checkbox").on("click", event => {
        setTimeout(function () {
            $("#edit-exercise-modal").modal("show");
        }, 500);

        const id = $(event.currentTarget).prop("id");

        // eslint-disable-next-line no-eval
        const data = eval($(`#data-${id}`).val());

        const ids = data.map(x => x.exercise_id);
        window.exerciseSchedules = data;
        addExercisesFromArray(ids);
    });

    $("#select-list-exercises").on("click", function (event) {
        selectListExercises(event);
    });

    $("#select-list-exercises2").on("click", function (event) {
        selectListExercises(event);
    });

    /*
     * Closes the model and validates the data entered in the send-message modal.
     */
    $("#save-list-details").on("click", function (event) {
        event.preventDefault();

        window.therapist_custom = window.therapist_custom == null ? false : window.therapist_custom;

        // Do validation
        const finalFormList = checkAddedExercises();
        if (!finalFormList) {
            // Probably means that there are no exercises
            swalWithEditList("", trans.forms["go-back"], "error", trans.forms["no-exercises-in-list-error"]);
            return;
        }

        if (onTherapistPage() && !window.therapist_custom) {
            // console.log("validating")
            // console.log(checkListDates($("#start_date").val(), $("#end_date").val()));

            // Alerts are thrown inside checkListDates
            if (!checkListDates($("#start_date").val(), $("#end_date").val())) return;

            if ($(this).hasClass("disabled")) {
                event.stopPropagation();
                $("#therapist-calender-add").text("add");
            } else {
                const html = createAgendaHTML();
                $("#therapist-calender").html(html);
                $("#therapist-calender-add").text("check");
            }

            // In case the send-message button is disabled, it should be re-evaluated, so we remove the class.
            $("#send-message").removeClass("disabled");
        } else {
            //  Update the complete list on admin page
            // window.listId;

            if (window.isDemo) {
                Swal.fire({
                    title: "Demo",
                    text: trans.demo["demo-send-succesfully"],
                    icon: "success"
                });
                return;
            }

            let toSendURL;
            const toSend = {
                description_nl: $("#description_nl").val(),
                description_en: $("#description_en").val(),
                title_nl: $("#title_nl").val(),
                title_en: $("#title_en").val(),
                exercise_list: JSON.stringify(finalFormList),
                id: window.listId
            };
            if (window.edit) {
                toSend.id = window.listId;
                toSendURL = "/admin/list/update";
                if (window.therapist_custom) toSendURL = "/therapist/list/update";
            } else {
                toSendURL = "/admin/list/createAdmin";
                if (window.therapist_custom) toSendURL = "/therapist/list/create";
            }

            // Convert JSON to FormData. This is needed so the request can also send an image
            // without submitting a form.
            const formData = Object.keys(toSend).reduce((fd, key) => {
                fd.append(key, toSend[key]);
                return fd;
            }, new FormData());

            // Actually add the image to the form data
            if ($("#image_list")[0].files != null && $("#image_list")[0].files[0] != null) {
                formData.append("image_list", $("#image_list")[0].files[0]);
            }

            $.post({
                url: toSendURL,
                processData: false,
                contentType: false,
                data: formData,
                headers: {
                    "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
                }
            })
                .done(_ => {
                    Swal.fire({ title: trans.forms.saved, titleText: "", icon: "success" }).then(() => {
                        window.location.reload();
                    });
                })
                .fail(_ => {
                    swalWithEditList("", trans.forms["go-back"], "error", trans.errors["empty-field"]);
                })
                .always(_ => _);
        }
    });

    /*
     * Activates the list settings tab pane thingy on the exercise modal.
     */
    $(".close-exercise-edit").on("click", () => {
        activateListEdit();
    });

    $(".add-psk")
        .off()
        .on("click", function () {
            window.firstPSKRef = $(this).parent().parent();
            window.pskCounter += 1;
            $(this).remove();
            addPSKFields(true);
        });

    $(".open-exercise-modal-link").on("click", () => {
        if (window.firstClickOpenExercise) {
            window.firstClickOpenExercise = false;
            window.getAllExercisesAjax($("#view-exercises"));
        }
    });

    /*
     * Loads the exercise lists via ajax and loads them into the DOM.
     */
    $(".open-exercise-list-modal-link").on("click", () => {
        $("#exercise-list").html("");
        window.getAllExerciseList($("#exercise-list"));
    });

    $(".open-exercise-list-custom-modal-link").on("click", () => {
        $("#exercise-list").html("");
        window.getCustomExerciseList($("#exercise-list"));
    });

    /*
     * This event fires whenever a document is selected.
     * It will append or remove documents that are selected/unselected.
     */
    $(".doc-checkbox").on("click", function () {
        if ($(this).attr("type") !== "checkbox") {
            return;
        }

        // Retrieve data from data elements from the select-doc.
        const thisElem = $(this).parents().closest(".checkbox");
        const pdfLink = thisElem.siblings("a").prop("href");
        const docId = thisElem.siblings(".doc_id").val();
        const data = JSON.parse(thisElem.siblings(".advice_data").val());
        const title = data.name;
        const elemId = data.id;

        // Shows the table when a row is added.
        const status = $(this).prop("checked");
        if (status) {
            $(`#no-documents-selected${docId}`).addClass("d-none");
            $(`#${docId}_thead`).removeClass("d-none");
        } else {
            $(`#${docId}_thead`).addClass("d-none");
            $(`#no-documents-selected${docId}`).removeClass("d-none");
        }

        if (status) {
            // Creates HTML for adding the row to the main page.
            let fileType = "";
            let buttons = "";

            // PDF has two links, one for viewing and one for downloading
            if (data.pdf) {
                fileType = crel("td", trans.forms.PDF);
                buttons = crel(
                    "td",
                    createDocButton(pdfLink, "remove_red_eye", true, false),
                    createDocButton(pdfLink, "save_alt", true, true)
                );
            } else {
                // Youtube link only has the watch option.
                fileType = crel("td", trans.forms["yt-link"]);
                buttons = crel("td", createDocButton(pdfLink, "play_circle_outline", true, false));
            }

            const html = crel("tr", { class: `${elemId}-table-row` }, crel("td", title), fileType, buttons);

            $(`.${docId}-table`).append(html);

            if (data.lists.length !== 0 && !window.docExercises[docId][elemId]) {
                Swal.fire({
                    title: trans.forms["you-sure"],
                    text: trans.forms["add-exercises-from-doc-msg"],
                    icon: "warning",
                    confirmButtonText: trans.forms.yes,
                    denyButtonText: trans.forms.cancel,
                    showDenyButton: true,
                    focusDeny: true
                }).then(add => {
                    if (add.isConfirmed) {
                        window.docExercises[docId][elemId] = true;
                        Swal.fire(trans.forms["added-exercises-from-doc"], {
                            icon: "success"
                        });
                        addExercisesFromDoc(data.lists);
                    }
                });
            }
            $(`#${docId}-add-icon`).text("check");
        } else {
            $(`.${docId}-table ${elemId}-table-row`).remove();
        }
    });
});
